<template>
  <g>
    <svg:style>
      .cls-4 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }
      .cls-4 {
        stroke-linecap: square;
        fill: url(#glass-pattern);
      }
    </svg:style>
    <g id="L25">
      <polygon id="glass"
               class="cls-4"
               :points="`
               ${inoxLeft + inoxWidth} ${inoxTop}
               ${inoxLeft} ${inoxTop}
               ${inoxLeft} ${doorTopHeight1 + 187.18}
               ${inoxLeft + inoxWidth} ${doorTopHeight1 + 187.18}
               ${inoxLeft + inoxWidth} ${inoxTop}
               ${inoxLeft + inoxWidth} ${inoxTop}
               `"/>
      <line id="impost"
            v-if="showImpost"
            class="line"
            :x1="inoxLeft"
            :y1="doorTopHeight1 + 187.17"
            :x2="inoxLeft + inoxWidth"
            :y2="doorTopHeight1 + 187.17"/>
      <line id="impost-2"
            v-if="showImpost"
            data-name="impost"
            class="line"
            :x1="inoxLeft + inoxWidth"
            :y1="doorTopHeight1 + 198.47"
            :x2="inoxLeft"
            :y2="doorTopHeight1 + 198.47"
      />
    </g>
  </g>
</template>


<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'showVariant',
    'laminationId',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
      leftOffset: 81,
    }
  },
  computed: {
    showImpost() {
      return this.showBg;
    },
    inoxY() {
      return this.doorTopHeight + this.inoxOffset;
    },
    inoxH() {
      return this.doorHeight - this.inoxOffset * 2;
    },
    inoxW() {
      return this.doorWidth - this.inoxOffset * 2;
    },
    inoxLeft() {
      return this.doorLeftWidth + this.inoxOffset;
    },
    inoxTop() {
      return this.doorTopHeight + this.inoxOffset;
    },
    inoxX() {
      return this.doorLeftWidth + this.inoxOffset;
    },
    inoxWidth() {
      return this.doorWidth - this.inoxOffset * 2;
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  },
}
</script>
